<template>
  <div>
    <v-form
      ref="form"
      id="editPageForm"
      lazy-validation
      v-if="content && Object.keys(content).length > 0"
    >
      <v-row
        v-for="(items, index) in getContentData"
        :key="index"
        class="flex mt-0"
        dense
      >
        <pageElement
          class="align-stretch"
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          :editMode="userIsAdministrator && editMode"
          :websiteId="page.WebsiteId.$oid"
          :pageId="page._id.$oid"
          :objectName="i"
          :colsWidth="item.colWidth"
          @removeItem="removeItem(i)"
          @moveUpNonRepPage="moveUpNonRepPage"
          @moveDownNonRepPage="moveDownNonRepPage"
          ref="pageElement"
        />
      </v-row>
    </v-form>
    <template v-if="userIsAdministrator && editMode">
      <br />
      <v-card outlined class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Add item") }}</h3>
          <addRemovePageElement
            @addItem="addObjectToPageContent"
            :enableAdd="true"
          />
        </v-card-text>
      </v-card>
    </template>
    <template
      v-if="
        $store.getters.hasUserRoleForPageItem({ role: 1 }) ||
        userIsAdministrator
      "
    >
      <cancelConfirmButtons
        ref="cancelConfirm"
        :isNonRepeatingPage="true"
        :confirmFunction="saveContent"
        :loading="savingContent"
        :confirmLabel="$t('Save page')"
        @goBack="$emit('goBack')"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    page: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      content: null,
      savingContent: false,
      success: "",
    };
  },
  created() {
    this.getPageContent();
  },
  watch: {
    content: {
      // This will let Vue know to look inside the object
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          !this.page.RepeatingContent &&
          !this.page.Custom &&
          this.$refs.cancelConfirm
        ) {
          this.$refs.cancelConfirm.cancelDisableToggle();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
      editMode: "editMode",
    }),

    getContentData() {
      let keys = Object.keys(this.content[0].Data);
      let topRowArray = [];
      let counter = 0;
      let indexArray = [];
      //loop over keys with index
      keys.forEach((element, index) => {
        if (
          this.content[0].Data[element].Required &&
          (this.content[0].Data[element].FieldValidation == undefined ||
            this.content[0].Data[element].FieldValidation == null) &&
          (this.content[0].Data[element].Type == "text" ||
            this.content[0].Data[element].Type == "richText")
        ) {
          this.content[0].Data[element].FieldValidation = true;
          this.$set(
            this.content[0].Data[element],
            "FieldValidationFuntion",
            "notEmptyStringRule"
          );
          /*  this.content[0].Data[element].FieldValidationFuntion =
            "notEmptyStringRule"; */
        }
        if (
          this.content[0].Data[element].Required &&
          (this.content[0].Data[element].FieldValidation == undefined ||
            this.content[0].Data[element].FieldValidation == null) &&
          this.content[0].Data[element].Type == "number"
        ) {
          this.content[0].Data[element].FieldValidation = true;
          this.$set(
            this.content[0].Data[element],
            "FieldValidationFuntion",
            "notEmptyStringRule"
          );
        }
        //check if contectdata has colWidth property, else push it in topRowArray (auto colWidth of 12)
        if (this.content[0].Data[element].colWidth) {
          //adding counter and item to index array.
          //For counter: try to get at 12 for one full row
          //For index array is to get all the previous item in the row
          counter += this.content[0].Data[element].colWidth;
          indexArray.push(index);
          if (counter % 12 > 0 && counter > 12) {
            // 2 states to pass counter is 12 counter is above 12 and is to big so push item and push index array
            //used when row has cols that are less then 12 cols long
            indexArray.pop();
            let itemsForArray = {};
            indexArray.forEach((el) => {
              itemsForArray[keys[el]] = this.content[0].Data[keys[el]];
            });
            indexArray = [];
            topRowArray.push(itemsForArray);
            let item = {};
            item[element] = this.content[0].Data[keys[index]];
            topRowArray.push(item);
            counter = 0;
          } else if (
            counter % 12 == 0 ||
            keys.length == 1 ||
            (index + 1 == keys.length && counter % 12)
          ) {
            // 3 states to pass is %12 or array is one item or is last item of array and not 12 long col
            let itemsForArray = {};
            indexArray.forEach((el) => {
              itemsForArray[keys[el]] = this.content[0].Data[keys[el]];
            });
            indexArray = [];
            topRowArray.push(itemsForArray);
            counter = 0;
          }
        } else {
          let item = {};
          this.content[0].Data[element]["colWidth"] = 12;
          item[element] = this.content[0].Data[element];
          topRowArray.push(item);
          counter = 0;
        }
      });

      return topRowArray;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    getPageContent() {
      if (!this.$route.params.id) {
        return;
      }
      this.content = null;

      //Content = array of content objects
      //This function is preferably used on pages with non-repeating objects
      this.$request.get(
        "/pagecontent/" +
          this.$route.params.id +
          "?language=" +
          this.currentLanguage,
        null,
        (res) => {
          if (res.success) {
            this.content = res.data;
            //Non-repeating pages have only one content object
            if (this.content[0].Data == null) {
              this.content[0].Data = {};
            }

            if (this.content[0].DisplayLabel == null) {
              this.content[0].DisplayLabel = "";
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    removeItem(index) {
      this.$delete(this.content[0].Data, index);
    },
    moveUpNonRepPage(index) {
      let keyOld;
      let keys = Object.keys(this.content[0].Data);

      keys.forEach((element, i) => {
        if (element == index && i != 0) {
          keyOld = element;
          keys[i] = keys[i - 1];
          keys[i - 1] = keyOld;
        }
      });
      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.content[0].Data[element];
      });
      this.content[0].Data = newObject;
    },
    moveDownNonRepPage(index) {
      let keyOld;
      let keys = Object.keys(this.content[0].Data);
      let isSet = false;
      keys.forEach((element, i) => {
        if (element == index && i !== keys.length - 1 && !isSet) {
          keyOld = element;
          keys[i] = keys[i + 1];
          keys[i + 1] = keyOld;
          isSet = true;
        }
      });

      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.content[0].Data[element];
      });
      this.content[0].Data = newObject;
    },
    addObjectToPageContent(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: this.$helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
        colWidth: 12,
      };
      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        this.$set(newObject, "FieldValidation", true);
        this.$set(newObject, "FieldValidationFuntion", "notEmptyStringRule");
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      this.$set(this.content[0].Data, properties.objectName, newObject);
    },
    saveContent() {
      //used when saving a page with non-repeating objects

      var formCustomElementsValid =
        this.$helpers.validateFormPageElements("editPageForm");
      var formIsValid = this.$refs.form.validate();

      if (formCustomElementsValid && formIsValid) {
        this.savingContent = true;
        this.content[0].DisplayLabel = this.$helpers.getDisplayLabel(
          this.content[0]
        );
        const langs = this.$config.contentLanguages;
        if (!this.content[0].DisplayLabels) {
          this.content[0].DisplayLabels = {};
        }
        langs.forEach((lang) => {
          this.content[0].DisplayLabels[lang] = this.$helpers.getDisplayLabel(
            this.content[0],
            this.page.DisplayLabelCustomFormat,
            lang
          );
        });
        this.$request.put("/content", this.content[0], (res) => {
          this.savingContent = false;

          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              this.$request.put(
                this.page.SynchronizationPutUrl,
                this.content[0],
                (res) => {
                  if (!res.success) {
                    const toaster = {
                      message: res.message,
                      timeout: false,
                      type: "ERROR",
                      toastClassName: ["toaster-color-error", "toaster-layout"],
                      bodyClassName: ["toaster-text-layout"],
                      icon: "mdi-alert-octagon",
                    };
                    this.$store.dispatch("setToaster", toaster);
                  }
                }
              );
            }
            const toaster = {
              message: this.$i18n.translate("The page was successfully saved"),
              timeout: this.$config.hideMessageTimeout,
              type: "SUCCESS",
              toastClassName: ["toaster-color-success", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-check-circle",
            };
            this.$store.dispatch("setToaster", toaster);
            this.$store.dispatch("setEditMode", false);
          }
        });
      } else {
        const toaster = {
          message: this.$i18n.translate(
            "Please make sure all text fields have a value"
          ),
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    },
  },
};
</script>

<style>
</style>